import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import * as routes from '../../../utils/routes';
import './PrimaryArticle.scss';
import { ImageCldy } from '../ImageCldy';

export type PrimaryArticleProps = {
  image: ImageDataLike;
  imageAlt: string;
  categoryLabel: string;
  header: string;
  categoryLink: string;
  articleLink: string;
  isSponsored?: boolean;
  className?: string;
  imgClassName?: string;
};
const PrimaryArticle: FunctionComponent<PrimaryArticleProps> = ({
  image,
  imageAlt,
  categoryLabel,
  header,
  categoryLink,
  articleLink,
  isSponsored = false,
  className = '',
  imgClassName = '',
}) => {
  return (
    <div className={`primary-article-container ${className}`}>
      <Link
        aria-label={`${header} article link`}
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-img-container"
      >
        {image?.data?.target && (
          <ImageCldy
            title={image.data.target.fields.title['en-US']}
            description={image.data.target.fields.description['en-US']}
            file={image.data.target.fields.file['en-US']}
            objectFit="contain"
            className={`article-img ${imgClassName}`}
            size="thumbnail"
            showCaption={false}
          />
        )}
      </Link>
      {!isSponsored && (
        <Link
          aria-label={`${categoryLabel} category link`}
          to={`${routes.SiteRoutes.CATEGORIES}/${categoryLink}`}
          className="category-label"
        >
          {categoryLabel}
        </Link>
      )}
      <Link
        aria-label={`${header} article link`}
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-header"
      >
        {header}
      </Link>
      {isSponsored && <p className="informative-text">SPONSORED</p>}
    </div>
  );
};

export default PrimaryArticle;

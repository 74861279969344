import React, { FunctionComponent, useMemo } from 'react';
import { useLocation } from '@reach/router';
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import { graphql } from 'gatsby';

import './Category.scss';

import website from 'config/website';

import { Breadcrumb } from 'components/Breadcrumb';
import { PrimaryArticle } from 'components/PrimaryArticle';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { SecondaryArticleHorizontal } from 'components/SecondaryArticleHorizontal';
import { SEO } from 'components/SEO';
import { FeatureArticle } from 'components/FeatureArticle';
import * as routes from '../../utils/routes';
import CategoryModel from 'models/Category.model';
import Subcategory from 'models/Subcategory.model';
import Article from 'models/Article.model';
import { sortArticleByCount } from 'utils/helpers';

export const query = graphql`
  query (
    $nonCategoryPage: Boolean = false
    $categorySlug: String = ""
    $subcategorySlug: String = ""
    $subcategoryPage: Boolean!
  ) {
    newArticles: allContentfulArticle(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { id: { ne: null } } }
    ) @include(if: $nonCategoryPage) {
      edges {
        node {
          id
          title
          slug
          metaDescription
          publishedDate(formatString: "Do MMMM, YYYY")
          subcategory: subcategory {
            name
            slug
            category {
              name
              slug
            }
          }
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
        }
      }
    }
    categoryArticles: allContentfulArticle(
      filter: {
        subcategory: {
          id: { ne: null }
          category: { slug: { eq: $categorySlug } }
        }
      }
      sort: { fields: publishedDate, order: DESC }
    ) @skip(if: $subcategoryPage) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "Do MMMM, YYYY")
          subcategory: subcategory {
            name
            slug
            category {
              name
              slug
            }
          }
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
        }
      }
    }
    subcategoryArticles: allContentfulArticle(
      filter: {
        subcategory: { id: { ne: null }, slug: { eq: $subcategorySlug } }
      }
      sort: { fields: publishedDate, order: DESC }
    ) @include(if: $subcategoryPage) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "Do MMMM, YYYY")
          subcategory: subcategory {
            name
            slug
            category {
              name
              slug
            }
          }
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

type CategoryProps = {
  data: {
    newArticles?: {
      edges: {
        node: Article;
      }[];
    };
    categoryArticles?: {
      edges: {
        node: Article;
      }[];
    };
    subcategoryArticles?: {
      edges: {
        node: Article;
      }[];
    };
  };
  pageContext: {
    category: CategoryModel;
    subcategory?: Subcategory;
    popularPage?: boolean;
    articleMonthlyViewCountMap: {
      [articleId: string]: number;
    };
  };
};
const Category: FunctionComponent<CategoryProps> = ({ data, pageContext }) => {
  const location = useLocation();

  const {
    category,
    subcategory,
    popularPage = false,
    articleMonthlyViewCountMap,
  } = pageContext;

  const articles =
    data.newArticles || data.categoryArticles || data.subcategoryArticles;

  const allArticlesSorted = useMemo(() => {
    if (!popularPage) {
      return articles;
    }
    return sortArticleByCount(articles, articleMonthlyViewCountMap);
  }, [articles, articleMonthlyViewCountMap, popularPage]);

  const allArticles = allArticlesSorted?.edges.map((edge) => edge.node) || [];

  const featureArticle = allArticles[0];
  const primaryArticles = allArticles.slice(1, 4);
  const restArticles = allArticles.slice(4);

  const breadcrumbPaths = [
    { label: 'Categories', link: routes.SiteRoutes.CATEGORIES },
  ];

  if (subcategory) {
    breadcrumbPaths.push({
      label: category.name,
      link: `${routes.SiteRoutes.CATEGORIES}/${category.slug}`,
    });
    breadcrumbPaths.push({ label: subcategory.name, link: `` });
  } else {
    breadcrumbPaths.push({ label: category.name, link: `` });
  }

  const metaDataCategory = subcategory || category;

  return (
    <PrimaryLayout>
      <SEO
        title={`${metaDataCategory.metaTitle || metaDataCategory.name} | ${
          website.titleAlt
        }`}
        desc={metaDataCategory.metaDescription}
        pathname={location.pathname}
      />
      <Container
        style={{ padding: 10, paddingRight: 0 }}
        fluid
        className="category-container"
      >
        <Col>
          <div className="breadcrumb-container">
            <Breadcrumb paths={breadcrumbPaths} />
          </div>

          {allArticles.length === 0 ? (
            <div>There are no articles for this category</div>
          ) : (
            <>
              <Row style={{ margin: 0 }}>
                <Col style={{ paddingLeft: 0 }} sm={12} md={6}>
                  <FeatureArticle
                    key={featureArticle.id}
                    header={featureArticle.title}
                    categoryLabel={featureArticle.subcategory.name}
                    image={featureArticle.thumbnailBam}
                    articleLink={featureArticle.slug}
                    categoryLink={`${featureArticle.subcategory.category.slug}/${featureArticle.subcategory.slug}`}
                  />
                </Col>
                <Col style={{ padding: 0 }}>
                  {primaryArticles.map((article, index) => (
                    <div key={article.id} className="side-articles-cont">
                      <Hidden xs sm>
                        <SecondaryArticleHorizontal
                          header={article.title}
                          isSponsored={false}
                          categoryLabel={article.subcategory.name}
                          image={article.thumbnailBam}
                          articleLink={article.slug}
                          categoryLink={`${article.subcategory.category.slug}/${article.subcategory.slug}`}
                        />
                      </Hidden>
                      <Visible xs sm>
                        <PrimaryArticle
                          header={article.title}
                          isSponsored={false}
                          categoryLabel={article.subcategory.name}
                          image={article.thumbnailBam}
                          articleLink={article.slug}
                          categoryLink={`${article.subcategory.category.slug}/${article.subcategory.slug}`}
                        />
                      </Visible>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row className="rest-articles">
                {restArticles.map((article) => (
                  <Col
                    className="article-item"
                    sm={12}
                    md={4}
                    lg={3}
                    key={article.id}
                  >
                    <Hidden xs sm>
                      <PrimaryArticle
                        key={article.id}
                        className="rest-article"
                        imgClassName="rest-article-img"
                        header={article.title}
                        isSponsored={false}
                        categoryLabel={article.subcategory.name}
                        image={article.thumbnailBam}
                        articleLink={article.slug}
                        categoryLink={`${article.subcategory.category.slug}/${article.subcategory.slug}`}
                      />
                    </Hidden>
                    <Visible xs sm>
                      <SecondaryArticleHorizontal
                        key={article.id}
                        className="small-secondary-article"
                        header={article.title}
                        isSponsored={false}
                        categoryLabel={article.subcategory.name}
                        image={article.thumbnailBam}
                        articleLink={article.slug}
                        categoryLink={`${article.subcategory.category.slug}/${article.subcategory.slug}`}
                      />
                    </Visible>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Col>
      </Container>
    </PrimaryLayout>
  );
};

export default Category;

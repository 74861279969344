import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import './PathLink.scss';

export type PathLinkProps = {
  className?: string;
  to: string;
  children: React.ReactText;
};
const PathLink: FunctionComponent<PathLinkProps> = ({
  children,
  className,
  to,
}) => {
  return (
    <Link
      className={`${className ? className : ''} path-link-container`}
      to={to}
    >
      <span>{children}</span>
    </Link>
  );
};

export default PathLink;
